import { Divider, Icon, Flag, Button } from 'semantic-ui-react';
import { Link } from 'gatsby';
import React from 'react';
import '../style.less';

const internalJob = props => {
  return (
    <div className='internal-job'>
      <div>
        <p className='jobTitle'>{props.job.jobTitle}</p>
      </div>
      <div>
        <span className='jobName'>
          <Icon name='home' />
          {props.job.name}
        </span>
        <span className='jobLocation'>
          <Flag style={{ marginLeft: '12px' }} name={props.job.country.toLowerCase()} />
          {props.job.city}, {props.job.country}
        </span>
        <br />
        <p className='postedDate'>
          <Icon name='clock' style={{ marginLeft: '3px', marginTop: '4px' }} />
          Posted {new Date(props.job.updatedAt).toLocaleDateString('en-US')}
        </p>
      </div>
      <Divider />
      <Link to={`/apply?jobId=${props.job._id}`}>
        <Button primary>Apply</Button>
      </Link>
      <div style={{ margin: '10px 0' }}>
        <p style={{ whiteSpace: 'pre-wrap' }}>{props.job.jobDescription}</p>
      </div>
    </div>
  );
};

export default internalJob;
