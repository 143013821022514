import { Component } from 'react';
import InternalJob from '../components/job/internalJob';
import { Dimmer, Loader, Container } from 'semantic-ui-react';
import React from 'react';
import { API_URL } from '../constants/constants';
import Helmet from 'react-helmet';

class Job extends Component {
  state = {
    loading: true,
    job: undefined
  };

  componentDidMount = async () => {
    const id = this.props.location.href.split('=')[1];
    const res = await fetch(`${API_URL}/job?jobId=` + id);
    const data = await res.json();
    this.setState({ job: data.job, loading: false });
  };
  render() {
    return this.state.job ? (
      <Container>
        <Helmet
          title={`eslbot | ${this.state.job.jobTitle}, ${this.state.job.city}, ${this.state.job.country}`}
          meta={[
            { name: 'description', content: `${this.state.job.jobTitle}, ${this.state.job.city}, ${this.state.job.country}` },
            {
              name: 'keywords',
              content: `tefl tesol esl ${this.state.job.jobTitle} ${this.state.job.city} ${this.state.job.country} ${this.state.job.name}`
            }
          ]}
        />
        <InternalJob job={this.state.job} />
      </Container>
    ) : (
      <Dimmer inverted active={this.state.loading}>
        <Loader content='Loading job' />
      </Dimmer>
    );
  }
}

export default Job;
